import { render, staticRenderFns } from "./claimType.vue?vue&type=template&id=4fa9b930&scoped=true&"
import script from "./claimType.js?vue&type=script&lang=js&"
export * from "./claimType.js?vue&type=script&lang=js&"
import style0 from "./claimType.css?vue&type=style&index=0&id=4fa9b930&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa9b930",
  null
  
)

export default component.exports