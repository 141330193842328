import Vue from 'vue';
import { Dialog } from 'vant';

Vue.use(Dialog);
export default {
  name: 'claimType',

  data() {
    return {

      visible:false
    };

  },
  methods: {
    back() {
      this.$router.go(-1);
    },
   agree(){
     this.visible=!this.visible
   },
   hospitalA(){
      if(this.visible==true){
        // Dialog.alert({
        //   message: '此功能暂未开放'
        // }).then(() => {
        //   // on close
        // });
         window.location.href = "http://210112.demo.md1927.com/item1";
      }else{
        Dialog.alert({
          message: '请允许授权山东京凯信息科技查询用户既往症信息'
        }).then(() => {
          // on close
        });
      }
   },
   hospitalB(){
    if(this.visible==true){
      // Dialog.alert({
      //   message: '此功能暂未开放'
      // }).then(() => {
      //   // on close
      // });
      window.location.href = "http://210112.demo.md1927.com/item2"
    }else{
      Dialog.alert({
        message: '请允许授权山东京凯信息科技查询用户既往症信息',

      }).then(() => {
        // on close
      });
    }
  }
  },

};
